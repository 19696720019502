import React from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';

import './header.styles.css';

class Header extends React.Component {
    state = { isOpen: false };

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        return (
            <MDBNavbar color='indigo darken-4' dark expand='md' fixed='top' scrolling transparent>
                <MDBNavbarBrand>
                    <strong>COLD ASSETS</strong>
                </MDBNavbarBrand>

                <MDBNavbarToggler onClick={this.toggleCollapse} />
                <MDBCollapse isOpen={this.state.isOpen} navbar>
                    <MDBNavbarNav left>
                        <MDBNavItem>
                            <MDBNavLink to='/' onClick={this.toggleCollapse}>
                                Home
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink to='/services' onClick={this.toggleCollapse}>
                                Services
                            </MDBNavLink>
                        </MDBNavItem>
                        {/* <MDBNavItem>
                            <NavLink className='navlink' to='/aboutus' onClick={this.toggleCollapse}>
                                About us
                            </NavLink>
                        </MDBNavItem> */}
                        {/* <MDBNavItem>
                            <NavLink className='navlink' to='/contactus' onClick={this.toggleCollapse}>
                                Contact us
                            </NavLink>
                        </MDBNavItem> */}
                    </MDBNavbarNav>
                    <MDBNavbarNav right>
                        {/* <MDBNavItem>
                            <a href='https://www.facebook.com' className='nav-link'>
                                <MDBIcon fab icon='facebook-f' />
                            </a>
                        </MDBNavItem> */}
                        {/* <MDBNavItem>
                            <a href='https://twitter.com' className='nav-link'>
                                <MDBIcon fab icon='instagram' />
                                Insagram
                            </a>
                        </MDBNavItem> */}
                        <MDBNavItem>
                            <a href='tel: 972-302-9892' className='nav-link'>
                                <MDBIcon icon='phone' className='pr-2' />
                                817 876 4673
                            </a>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        );
    }
}

export default Header;
