import React from 'react';
import { MDBView, MDBMask, MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';

import './landing-page.styles.css';

class LandingPage extends React.Component {
    render() {
        return (
            <div id='mainview'>
                <MDBView>
                    <img className='img-fluid' alt='' />
                    <MDBMask className='d-flex align-items-center gradient'>
                        <MDBContainer className='my-auto'>
                            <MDBRow>
                                <MDBCol md='8' className='white-text text-center mx-auto'>
                                    <MDBAnimation type='fadeInLeft' delay='.3s'>
                                        <h1 className='h1-responsive mt-sm-4 mx-sm-2'>COMMERCIAL AIR AND HEATING INSTALLATIONS</h1>
                                        <hr />
                                        <h6 className='mb-lg-3'></h6>
                                        <h5 className='mb-sm-1 mb-lg-5'>Engineering, Design, Installation, Service & Maintenance of HVAC Systems</h5>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBMask>
                </MDBView>

                <main>
                    <div className='container'>
                        <section>
                            <h2 className='my-5 h3 text-center'>A Full-Service HVAC Contractor</h2>

                            <div className='row features-small mb-5 mt-3 wow fadeIn'>
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <i className='fas fa-check-circle fa-2x indigo-text'></i>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='feature-title'>Engineering and Design Services</h6>
                                            {/* <p className='grey-text'>details</p> */}
                                            <div style={{ height: '15px' }}></div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-2'>
                                            <i className='fas fa-check-circle fa-2x indigo-text'></i>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='feature-title'>Turnkey Installations</h6>
                                            {/* <p className='grey-text'>details</p> */}
                                            <div style={{ height: '15px' }}></div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-2'>
                                            <i className='fas fa-check-circle fa-2x indigo-text'></i>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='feature-title'>Proactive maintenance agreements</h6>
                                            {/* <p className='grey-text'>details</p> */}
                                            <div style={{ height: '15px' }}></div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-2'>
                                            <i className='fas fa-check-circle fa-2x indigo-text'></i>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='feature-title'>Conceptual budgeting and cost estimates</h6>
                                            {/* <p className='grey-text'>details</p> */}
                                            <div style={{ height: '15px' }}></div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <i className='fas fa-check-circle fa-2x indigo-text'></i>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='feature-title'>Service and renovation of existing systems</h6>
                                            {/* <p className='grey-text'>details</p> */}
                                            <div style={{ height: '15px' }}></div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <i className='fas fa-check-circle fa-2x indigo-text'></i>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='feature-title'>LEED-certified projects</h6>
                                            {/* <p className='grey-text'>details</p> */}
                                            <div style={{ height: '15px' }}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 flex-center'>
                                    <MDBAnimation type='fadeInRight' delay='.3s' reveal>
                                        <img className='z-depth-0 img-fluid' src={require(`../../assets/commercialhvac.jpg`)} alt='LG equipment' />
                                    </MDBAnimation>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>

                <hr className='mb-5' />

                <section>
                    <h2 className='my-5 h3 text-center'>24 hour 7 day - full service - call us for a quote - 817 876 4673</h2>
                    <div class='my-5'>
                        <section class='grey lighten-2 py-5'>
                            <div class='flex-center'>
                                <div class='row'>
                                    <div class='col-md-2 col-4 flex-center'>
                                        <img src={require(`../../assets/png/vetsfirst.png`)} class='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                    </div>
                                    <div class='col-md-2 col-4 flex-center'>
                                        <img src={require(`../../assets/png/sdvosb.png`)} class='img-fluid wow fadeIn' data-wow-delay='.4s' alt='something' />
                                    </div>
                                    <div class='col-md-3 col-4 flex-center'>
                                        <img src={require(`../../assets/png/esco.png`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                    </div>

                                    <div class='col-md-3 col-4 flex-center'>
                                        <img src={require(`../../assets/png/osha.png`)} class='img-fluid wow fadeIn' data-wow-delay='.4s' alt='something' />
                                    </div>

                                    <div class='col-md-2 col-4 flex-center'>
                                        <img src={require(`../../assets/png/tdlr2.png`)} class='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
        );
    }
}

export default LandingPage;
