import React from 'react';
import { MDBIcon } from 'mdbreact';

const Footer = () => {
    return (
        // <footer className='page-footer font-small indigo pt-4'>
        //     <div className='container-fluid text-center text-md-left'>
        //         <div className='row'>
        //             <div className='col-md-6 mt-md-0 mt-3'>
        //                 <h5 className='text-uppercase'>Footer Content</h5>
        //                 <p>Here you can use rows and columns to organize your footer content.</p>
        //             </div>

        //             <hr className='clearfix w-100 d-md-none pb-3' />

        //             <div className='col-md-3 mb-md-0 mb-3'>
        //                 <h5 className='text-uppercase'>Links</h5>

        //                 <ul className='list-unstyled'>
        //                     <li>Link</li>
        //                     <li>Link</li>
        //                     <li>Link</li>
        //                     <li>Link</li>
        //                 </ul>
        //             </div>

        //             <div className='col-md-3 mb-md-0 mb-3'>
        //                 <h5 className='text-uppercase'>Links</h5>

        //                 <ul className='list-unstyled'>
        //                     <li>Link</li>
        //                     <li>Link</li>
        //                     <li>Link</li>
        //                     <li>Link</li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>

        //     <div className='footer-copyright text-center py-3'>
        //         &copy; {new Date().getFullYear()} Copyright:
        //         <a href='http://www.coldassets.com'> coldassets.com</a>
        //     </div>
        // </footer>
        <footer className='page-footer font-small indigo darken-4 py-4 '>
            <div className='container align-items-center'>
                <div className='row  align-items-center'>
                    <div className='col-md-6 col-7 d-flex justify-content-start'>
                        <div className='footer-copyright text-center bg-transparent '>
                            &copy; {new Date().getFullYear()} Copyright:
                            <a href='http://www.coldassets.com'> coldassets.com</a>
                        </div>
                    </div>
                    <div className='col-md-6 col-5 d-flex justify-content-end'>
                        <div className='text-center bg-transparent '>
                            <a href='tel: 972-302-9892' className='nav-link'>
                                <MDBIcon icon='phone' className='pr-2' />
                                817 876 4673
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
