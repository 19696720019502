import React from 'react';
import { MDBAnimation, MDBMask } from 'mdbreact';

import './services.styles.css';

const Services = () => {
    return (
        <div id='services'>
            <div className='view'>
                <div className='mask rgba-black-light d-flex justify-content-center align-items-center gradient'>
                    <div className='text-center white-text mx-5'>
                        <MDBAnimation type='fadeInLeft' delay='.3s'>
                            <h1 className='h1-responsive mt-sm-4 mx-sm-2'>SERVICES</h1>
                        </MDBAnimation>
                    </div>
                </div>
            </div>
            <div className='container my-5 px-5 pt-5 pb-4 z-depth-1'>
                <section className='dark-grey-text'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 mb-4'>
                            <h2 className='font-weight-normal mb-4'>Design and Engineering</h2>
                            <p className='lead text-muted'>With today's focus on receiving maximum investment return, finding the right mechanical contractor for your HVAC design and engineering project is critical. At Cold Assets, our knowledgeable, highly trained staff can lead you through...</p>
                            {/* <p className='text-muted'>We can combine beautiful, modern designs with clean, functional. And best of all, we are super passionate about our work. Thought does cognitive into follow and rationale annoyed.</p> */}
                        </div>

                        <div className='col-lg-6 mb-4'>
                            <div id='mdb-lightbox-ui'></div>

                            <div className='d-grid mdb-lightbox'>
                                <figure className='item'>
                                    <img src={require(`../../assets/design.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/design2.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/design3.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/design4.jpg`)} alt='placeholder' />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='container my-5 px-5 pt-5 pb-4 z-depth-1'>
                <section className='dark-grey-text'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 mb-4 order-1 order-md-2'>
                            <h2 className='font-weight-normal mb-4'>Installation</h2>
                            <p className='lead text-muted'>Cold Assets has the knowledge and resources to complete each project to the highest standards in the industry. General contractors, building owners and property managers throughout Chicagoland rely on our commercial HVAC installation...</p>
                            {/* <p className='text-muted'>We can combine beautiful, modern designs with clean, functional. And best of all, we are super passionate about our work. Thought does cognitive into follow and rationale annoyed.</p> */}
                        </div>
                        <div className='col-lg-6 mb-4 order-2 order-md-1'>
                            <div id='mdb-lightbox-ui'></div>

                            <div className='d-grid mdb-lightbox'>
                                <figure className='item'>
                                    <img src={require(`../../assets/installation.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/installation2.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/installation3.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/installation4.jpg`)} alt='placeholder' />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='container my-5 px-5 pt-5 pb-4 z-depth-1'>
                <section className='dark-grey-text'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 mb-4'>
                            <h2 className='font-weight-normal mb-4'>Maintenance & Services</h2>
                            <p className='lead text-muted'>“Outstanding Customer Service,” “Experience,” “Confidence”… these are just a few of the words customers use when describing Cold Assets. Our maintenance staff strives to provide one thing — excellent customer service. Whether you are...</p>
                            {/* <p className='text-muted'>We can combine beautiful, modern designs with clean, functional. And best of all, we are super passionate about our work. Thought does cognitive into follow and rationale annoyed.</p> */}
                        </div>

                        <div className='col-lg-6 mb-4'>
                            <div id='mdb-lightbox-ui'></div>

                            <div className='d-grid mdb-lightbox'>
                                <figure className='item'>
                                    <img src={require(`../../assets/maintenance2.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/maintenance4.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/maintenance3.jpg`)} alt='placeholder' />
                                </figure>
                                <figure className='item'>
                                    <img src={require(`../../assets/maintenance.jpg`)} alt='placeholder' />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <hr className='mb-5' />

            <section>
                <h2 className='my-5 h3 text-center'>certified products</h2>
                <div className='my-5'>
                    <section className='grey lighten-2 py-5'>
                        <div className='flex-center'>
                            <div className='row'>
                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/AAON_logo.png/320px-AAON_logo.png' className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>

                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/ABB_logo.svg/320px-ABB_logo.svg.png' className='img-fluid wow fadeIn' data-wow-delay='.4s' alt='something' />
                                </div>

                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src='https://upload.wikimedia.org/wikipedia/commons/4/46/AERCO-Logo.png' className='img-fluid wow fadeIn' data-wow-delay='.4s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/uvr_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/lg_tagline.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>

                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/condair_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/cook_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>

                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/enviro_tec_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.4s' alt='something' />
                                </div>

                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/johnson_controls.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.4s' alt='something' />
                                </div>

                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/nortek_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/orion_control_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>

                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/seresco.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.4s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/temtrol_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.4s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/armstrong_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/vibro_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 flex-center p-4'>
                                    <img src={require(`../../assets/svg/york_logo.svg`)} className='img-fluid wow fadeIn' data-wow-delay='.2s' alt='something' />
                                </div>
                                <div className='col-md-2 col-4 flex-center'>
                                    <img src={require(`../../assets/svg/bac_logo.svg`)} className='img-fluid wow fadeIn pb-3' data-wow-delay='.2s' alt='something' />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    );
};

export default Services;
